
import { Vue, Component } from "vue-property-decorator";
import { mdiArrowLeft, mdiChevronRight, mdiClose } from "@mdi/js";

interface WhatsappIntegration {
  id: string;
  type: "whatsapp";
  settings: {
    enabled: boolean;
    text: string;
    phone_number: string;
    icon: string;
  };
}

interface PhoneCallConfiguration {
  id: string;
  type: "phone-call";
  settings: {
    enabled: boolean;
    text: string;
    phone_number: string;
    icon: string;
  };
}

export interface LinkIntegration {
  type: "link";
  id: string;
  settings: {
    enabled: boolean;
    color: string;
    title: string;
    url: string;
    text: string;
    icon: string;
  };
}

export interface ContentIntegration {
  type: "content";
  id: string;
  settings: {
    enabled: boolean;
    title: string;
    summary: string;
    text: string;
    icon: string;
  };
}

interface Project {
  name: string;
  icon: string;
  greeting: string;
  greeting_timeout_seconds: number;
  color: string;
}

interface Settings {
  project: Project | null;
  integrations: Array<
    | WhatsappIntegration
    | ContentIntegration
    | PhoneCallConfiguration
    | LinkIntegration
  >;
}

@Component
export default class Widget extends Vue {
  settingsLoaded = false;
  tooltipActive = false;
  windowOpen = false;
  showGreeting = false;
  rightIcon: string = mdiChevronRight;
  closeIcon: string = mdiClose;
  backIcon: string = mdiArrowLeft;
  defaultLinkColor = "#1e88e5";
  settings: Settings | null = null;
  activeIntegrationId: string | null = null;

  get activeIntegration(): ContentIntegration | null {
    const integration = this.settings?.integrations.find(
      (x) => x.id === this.activeIntegrationId,
    );
    if (!integration) {
      return null;
    }
    return integration as ContentIntegration;
  }

  get backgroundImage() {
    if (this.windowOpen) {
      return this.iconUrl("close");
    }
    return this.iconUrl(this.settings?.project?.icon as string);
  }

  get whatsappIconStyle() {
    return {
      backgroundImage: `url(${this.iconUrl("whatsapp")}`,
      backgroundRepeat: "no-repeat",
      height: "24px",
      width: "24px",
      backgroundSize: "cover",
    };
  }

  get phoneCallIconStyle() {
    return {
      backgroundImage: `url(${this.iconUrl("phone-call")}`,
      backgroundRepeat: "no-repeat",
      height: "24px",
      width: "24px",
      backgroundSize: "cover",
    };
  }

  get widgetStyle() {
    return {
      backgroundColor: this.settings?.project?.color,
      float: "right",
    };
  }

  get widgetImageStyle() {
    return {
      background: `url(${this.backgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    };
  }

  get isMobile(): boolean {
    return /iPhone|iPod|Android/i.test(navigator.userAgent);
  }

  get cdnBaseUrl(): string {
    return process.env.VUE_APP_BASE_URL_CDN;
  }

  mounted() {
    if (window.SB_USER_ID && window.SB_PROJECT_ID) {
      this.loadSettings(window.SB_USER_ID, window.SB_PROJECT_ID);
    }
  }

  toggleWidgetWindow() {
    if (this.windowOpen) {
      this.closeWidgetWindow();
      return;
    }
    this.openWidgetWindow();
  }

  openWidgetWindow() {
    this.windowOpen = true;
    this.tooltipActive = false;
  }

  openWhatsappChat(phoneNumber: string) {
    window
      .open(`https://wa.me/${phoneNumber.replace("+", "")}`, "_blank")
      ?.focus();
  }

  linkIconStyle(icon: string) {
    return {
      backgroundImage: `url(${this.iconUrl(icon)}`,
      backgroundRepeat: "no-repeat",
      height: "24px",
      width: "24px",
      backgroundSize: "cover",
    };
  }

  openPhoneCall(phoneNumber: string) {
    window.open(`tel:${phoneNumber}`)?.focus();
  }

  openLink(url: string) {
    window.location.href = url;
  }

  openContentIntegration(integrationId: string) {
    this.activeIntegrationId = integrationId;
  }

  closeWidgetWindow() {
    this.activeIntegrationId = null;
    this.windowOpen = false;
  }

  displayGreeting() {
    if (this.settings?.project?.greeting) {
      this.showGreeting = true;
      setTimeout(() => {
        if (!this.windowOpen) {
          this.tooltipActive = true;
        }
      }, this.settings.project.greeting_timeout_seconds * 1000);
    }
  }

  iconUrl(icon: string) {
    return this.cdnBaseUrl + "/icons/" + icon + ".svg";
  }

  closeActiveIntegration() {
    this.activeIntegrationId = null;
  }

  loadSettings(userId: string, projectId: string) {
    fetch(
      `${process.env.VUE_APP_BASE_URL_BACKEND}/v1/settings/${userId}/projects/${projectId}`,
    )
      .then((response) => response.json())
      .then((response) => {
        this.settings = response.data;
        this.settingsLoaded = true;
        this.displayGreeting();
      });
  }
}
